import { ReactComponent as MinusIcon } from "../assets/minus.svg";

export function RoadExtractor(props) {
  // create a copy of the props.data array using the spread operator
  const dataCopy = [...props.data];
  // create a compare function that takes two objects and returns a number based on their popup.title property
  const compare = (a, b) => {
    // check if both objects have a popup property that is not null or undefined
    if (a.popup && b.popup) {
      // get the popup.title property of each object, or use an empty string as a default value
      const titleA = a.popup.title ?? '';
      const titleB = b.popup.title ?? '';
      // use the localeCompare method to compare the titles in a case-insensitive way
      return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' });
    } else {
      // if one or both objects do not have a popup property, return 0 to keep them in the same order
      return 0;
    }
  };
  const sortedData = dataCopy.sort(compare);

  return (
    <div className="divide-y-2 divide-gray-200 mt-2 p-0 text-primary  ">
      {
        sortedData.map((route, index) => {
          return (
            route.type === 'road' &&
            <div className={ "p-2 border-b cursor-pointer " + (props.activeRoute === index ? "bg-rose-600 text-white" : "text-primary") } key={ index }
              onClick={ () => {
                props.setActiveRoute(index);
                props.setLeftBar(true);
              } }>
              <section>
                <div className="flex ">
                  <div>
                    <MinusIcon className={ "mx-3 w-5 h-5 " + (props.activeRoute === index ? "fill-gray-100" : "fill-rose-600") } />
                  </div>
                  <div>
                    <h2 className="capitalize text-sm"> { `${route.popup.title} ${route.popup.section}` } </h2>
                  </div>
                </div>
              </section>
            </div>
          )
        }
        ) }

    </div>
  );
}