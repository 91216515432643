import React, { useEffect ,useRef } from "react";
import { Popup } from "react-leaflet";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import { ReactComponent as EndDateIcon } from "../assets/endDate.svg";
import { ReactComponent as CauseIcon } from "../assets/cause.svg";
import { ReactComponent as DescriptionIcon } from "../assets/description.svg";

export const PopupMessageBox = (props) => {
  

  return (
    <Popup >

      <div className="w-80 bg-white border rounded">
        <div className={ `flex flex-col ${props.bgColor} text-white py-2 px-2 border rounded-t` }>
          <div className="flex h-auto w-full">
            <div className="item w-1/12 h-auto mt-1">
              <PinIcon className="fill-current text-white w-5 h-5" />
            </div>
            <div className="item w-11/12 h-auto mx-2">
              <h2 className="font-bold flex-1 text-base">{ `${props.title}` }</h2>
            </div>
          </div>
          <div className="flex h-auto w-full space-y-2  ">
            <div className="item w-1/12 h-auto mt-3">
              <CalendarIcon className="fill-current text-white w-4 h-4" />
            </div>
            <div className="item w-11/12 h-auto mx-2">
              <div className="text-sm">Road close from { props.route.popup.date }</div>
              {
                props.route.popup.end_date && (
                  <div className="item ">
                    <div className="text-sm">Till { props.route.popup.end_date }</div>
                  </div>)
              }

            </div>

          </div>


        </div>

        <div className="py-2 px-2 border-b border-slate-300">
          <h3 className="font-bold py-1">Information for users</h3>
          <table className="w-full text-xs border-separate border-spacing-3">
            <tbody>
              { props.route.popup.reason && (
                <tr className="">
                  <td className="flex space-x-4 h-auto w-full items-center">
                    <div className="item w-1/12 h-auto">
                      <CauseIcon className="fill-rose-600 w-5 h-5" />
                    </div>
                    <div className="item w-11/12 h-auto mx-2">
                      <span className="font-medium w-1/3 text-stone-600">Cause</span>
                    </div>
                  </td>

                  <td className=" w-2/3">{ props.route.popup.reason }</td>
                </tr>
              ) }
              { props.route.popup.section && (
                <tr className="">
                  <td className="flex space-x-4 h-auto w-full items-center">
                    <div className="item w-1/12 h-auto">
                      <PinIcon className="fill-rose-600 w-5 h-5" />
                    </div>
                    <div className="item w-11/12 h-auto mx-2">
                      <span className="font-medium w-1/3 text-stone-600">Name</span>
                    </div>
                  </td>
                  <td className=" w-2/3">{ props.route.popup.section }</td>
                </tr>
              ) }

              { props.route.popup.description && (
                <tr className="">
                  <td className="flex space-x-4 h-auto w-full items-center">
                    <div className="item w-1/12 h-auto">
                      <DescriptionIcon className="fill-rose-600 w-5 h-5" />
                    </div>
                    <div className="item w-11/12 h-auto mx-2">
                      <span className="font-medium w-1/3 text-stone-600">Description</span>
                    </div>
                  </td>
                  <td className=" w-2/3">{ props.route.popup.description }</td>
                </tr>
              ) }
            </tbody>
          </table>
        </div>


        <div className="flex justify-between h-auto w-full py-2 px-2 text-xxs">
          <div className="flex space-x-3 w-auto h-auto">
            <a href="https://www.nlc.com.pk/index.htm">
              <img src="/icons/NLC.png" className="w-5" alt="National Logistics Cell" title="National Logistics Cell" />
            </a>
            <a href="https://www.dhakarachi.org/">
              <img src="/icons/logo_dha.png" className="w-5" alt="Defence Housing Authority" title="Defence Housing Authority" />
            </a>
          </div>

          <div className="item w-auto h-auto"> Last Update: { props.route.popup.lastupdate }</div>
        </div>

      </div>
    </Popup>
  );
}


export default PopupMessageBox;