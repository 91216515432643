import L from "leaflet";

export const pinIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

export const svgIcon = L.divIcon({
  html: `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M9.5 10.287c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm4.5.495c0-.137-.112-.248-.25-.248h-3.5c-.138 0-.25.111-.25.248s.112.248.25.248h3.5c.138-.001.25-.112.25-.248zm2-.495c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm-8.649-3.219h-1.101c-.138 0-.25.111-.25.248v.253c0 .393.463.49.808.49l.543-.991zm9.659 1.569c-.435-.8-.866-1.597-1.342-2.382-.393-.649-.685-.96-1.375-1.083-.698-.124-1.341-.172-2.293-.172s-1.595.048-2.292.172c-.69.123-.982.433-1.375 1.083-.477.785-.907 1.582-1.343 2.382-.344.63-.49 1.194-.49 1.884 0 .653.21 1.195.5 1.89v1.094c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-.495h6.5v.495c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-1.094c.29-.695.5-1.237.5-1.89 0-.69-.146-1.254-.49-1.884zm-7.821-1.873c.335-.554.426-.569.695-.617.635-.113 1.228-.157 2.116-.157s1.481.044 2.116.156c.269.048.36.064.695.617.204.337.405.687.597 1.03-.728.11-2.01.266-3.408.266-1.524 0-2.759-.166-3.402-.275.19-.34.389-.686.591-1.02zm5.798 5.256h-5.974c-.836 0-1.513-.671-1.513-1.498 0-.813.253-1.199.592-1.821.52.101 1.984.348 3.908.348 1.74 0 3.28-.225 3.917-.333.332.609.583.995.583 1.805 0 .828-.677 1.499-1.513 1.499zm2.763-4.952c.138 0 .25.111.25.248v.253c0 .393-.463.49-.808.49l-.543-.99h1.101zm-5.75-7.068c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8z"/></svg>`,
  className: "",
  iconSize: [24, 40],
  iconAnchor: [12, 40],
});
