import React from "react";
import { ReactComponent as MapIcon } from "../assets/google-map.svg";

export function Header() {
  const shareUrl = "https://www.map.thinktransportation.net/";
  const title = 'Think Transportation';

  return <header className="absolute flex items-center w-full h-16 bg-white bg-opacity-90 border-b-2 border-red-600 z-20 shadow-md">
      <div className="z-20">
      <div className="ml-10 md:ml-14">
        <a href="https://thinktransportation.net/">
          <img src="/ThinkLogo1.png" className="w-20" alt="Think Transportation" title="Think Transportation" />
        </a>
      </div>
      <div className="absolute top-0 right-10 flex">
        {/* facebook */}
        {/* <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}  rel="noopener noreferrer">
          <img src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-64.png" className="w-6 sm:w-8 mr-2 mt-2" alt="Think Transportation" title="Facebook" />
        </a> */}
        {/* twitter */}
        {/* <a target="_blank" href={ `https://twitter.com/intent/tweet/?text=${title}&url=${shareUrl}` } rel="noopener noreferrer">
          <img src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-64.png" className="w-6 sm:w-8  mr-2 mt-2" alt="Think Transportation" title="Twitter" />
        </a> */}
        {/* linkdin */}
        {/* <a target="_blank" href={ `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${title}` } rel="noopener noreferrer">
          <img src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-64.png" className="w-6 sm:w-8  mr-2 mt-2" alt="Think Transportation" title="Linkdin" />
        </a> */}
        {/* whatsapp share  */ }
        {/* <a target="_blank" href={ `https://api.whatsapp.com/send?text=${title} ${shareUrl}` } rel="noopener noreferrer">
          <img src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-64.png" className="w-6 sm:w-8 mr-2 mt-2" alt="Think Transportation" title="Whatsapp" />
        </a> */}
        <a target="_blank" href="https://www.google.com/maps/d/viewer?mid=1HLxll_toNs7G4hqHHqqwoAWBraxqTqU" className="hover:underline"> <MapIcon className="w-10 ml-2 fill-red-600" title="Open Google Map" /></a>
      </div>
    </div>
  </header>;
}
